















import { useToolState } from '@/store';

export default {
  props: {
    timeout: {
      type: Number,
      default: 4000,
      required: false
    }
  },
  setup(props, ctx) {
    const { snackBar } = useToolState(['snackBar']);

    return { snackBar };
  }
};
