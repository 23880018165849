import axios from 'axios';
import { MutationTypes } from './mutations';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  fetchTeamList = 'fetchTeamList'
}

export const actions = {
  async postQuestion({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_TEAM_SERVICE}/forum/question`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      commit(MutationTypes.ADD_QUESTION, resp.data.data.question);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async updateQuestion({ commit, dispatch }, data) {
    try {
      const payload = { ...data };
      delete payload._id;
      const resp = await axios.patch(
        `${process.env.VUE_APP_TEAM_SERVICE}/forum/question?question_id=${data._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.UPDATE_QUESTION, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async fetchQuestions({ commit, dispatch }, data) {
    try {
      let queryParams = `program_id=${data.program_id}`;
      if (data?.public_id) {
        queryParams = `${queryParams}&public_id=${data?.public_id}`;
      }
      const resp = await axios.get(
        `${process.env.VUE_APP_TEAM_SERVICE}/forum/questions?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      commit(MutationTypes.GET_QUESTIONS, resp.data.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
};
