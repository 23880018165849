import { watch } from '@vue/composition-api';
import store, { useUserGetters, useRealmAppState, useUserActions } from '@/store';

const { app } = useRealmAppState(['app']);

const { getUser } = useUserGetters(['getUser']);
const { fetchUser } = useUserActions(['fetchUser']);
const { fetchUserById } = useUserActions(['fetchUserById']);
// eslint-disable-next-line import/prefer-default-export
export const setUser = watch(
  getUser,
  async newUser => {
    if (!newUser && localStorage.getItem('apollo-token')) {
      await fetchUser(localStorage.getItem('apollo-token'));
    } else if (newUser && localStorage.getItem('apollo-token')) {
      const user = await fetchUserById({ user_id: newUser._id?.toString() });
    }
  },
  {
    immediate: true
  }
);
