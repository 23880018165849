var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"depressed":"","flat":"","color":"primary","height":"75"}},[_c('a',{staticClass:"pt-3",attrs:{"href":"https://www.pilotcity.com/"}},[_c('img',{staticClass:"nav__logo",attrs:{"src":require("@/assets/Pilotcity_logo.png")}})]),_c('v-toolbar-title',[_c('span',{staticClass:"text-h5 signup__header font-weight-black text-sm-h4"})]),(_vm.loading)?_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"blue"}}):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mobile-hamburger-menu",on:{"click":_vm.openMenu}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuList),expression:"menuList"}],staticClass:"menu-items"},[(!_vm.user)?_c('v-btn',{staticClass:"mr-4 ml-2",attrs:{"depressed":"","large":"","rounded":"","color":"white","outlined":"","to":{ name: 'login' },"ripple":false}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Login")])]):_vm._e(),(!_vm.user)?_c('v-btn',{staticClass:"white--text mr-2 ml-2",attrs:{"depressed":"","large":"","rounded":"","color":"#828282","ripple":false,"to":{ name: 'signup' }}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Signup")])]):_vm._e(),(_vm.user && _vm.permissions.includes('can_explore_programs'))?_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"large":"","depressed":"","rounded":"","dark":"","color":"green","ripple":false},on:{"click":function($event){return _vm.$router.push({ name: 'explore' })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-sign-direction")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Explore Programs")])],1):_vm._e(),(
          _vm.user &&
          _vm.user.userTypes &&
          _vm.userTypeValues.some(function (i) { return _vm.user.userTypes.includes(i); }) &&
          _vm.permissions.includes('can_create_program')
        )?_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"large":"","depressed":"","rounded":"","dark":"","color":"purple","ripple":false},on:{"click":function($event){_vm.isSetupWizardOpen = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-outline")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Create Program")])],1):_vm._e(),(_vm.user)?_c('v-btn',{staticClass:"ml-2",class:_vm.$vuetify.breakpoint.xs ? 'mr-2' : 'mr-4',attrs:{"large":"","depressed":"","rounded":"","outlined":"","color":"white","ripple":false,"to":"/portfolio"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-fingerprint")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("My Portfolio")])],1):_vm._e()],1),_c('div',{staticClass:"menu-items-desktop"},[(!_vm.user)?_c('v-btn',{staticClass:"mr-4 ml-2",attrs:{"depressed":"","large":"","rounded":"","color":"white","outlined":"","to":{ name: 'login' },"ripple":false}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Login")])]):_vm._e(),(!_vm.user)?_c('v-btn',{staticClass:"white--text mr-2 ml-2",attrs:{"depressed":"","large":"","rounded":"","color":"#828282","ripple":false,"to":{ name: 'signup' }}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Signup")])]):_vm._e(),(_vm.user && _vm.permissions.includes('can_explore_programs'))?_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"large":"","depressed":"","rounded":"","dark":"","color":"green","ripple":false},on:{"click":function($event){return _vm.$router.push({ name: 'explore' })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-sign-direction")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Explore Programs")])],1):_vm._e(),(
          _vm.user &&
          _vm.user.userTypes &&
          _vm.userTypeValues.some(function (i) { return _vm.user.userTypes.includes(i); }) &&
          _vm.permissions.includes('can_create_program')
        )?_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"large":"","depressed":"","rounded":"","dark":"","color":"purple","ripple":false},on:{"click":function($event){_vm.isSetupWizardOpen = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-outline")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Create Program")])],1):_vm._e(),(_vm.user)?_c('v-btn',{staticClass:"mr-4 ml-2",attrs:{"large":"","depressed":"","rounded":"","outlined":"","color":"white","ripple":false,"to":"/portfolio"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-fingerprint")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("My Portfolio")])],1):_vm._e()],1),(_vm.user)?_c('v-menu',{attrs:{"offset-y":"","ripple":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.user)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"primary","dark":"","depressed":""}},'v-btn',attrs,false),on),[_c('Profile',{attrs:{"size":45,"user":_vm.user}})],1):_vm._e()]}}],null,false,1955841814)},[_c('v-btn',{staticClass:"navbar__logout",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":_vm.logout}},[_vm._v("Logout")])],1):_vm._e()],1),(_vm.isSetupWizardOpen)?_c('SetupWizard',{attrs:{"enabled":_vm.isSetupWizardOpen,"default-open":_vm.isSetupWizardOpen,"is-display":_vm.isSetupWizardOpen},on:{"close":function($event){_vm.isSetupWizardOpen = false},"openCreateProgram":_vm.openCreateProgram}}):_vm._e(),(_vm.selectTemplate)?_c('MSelectTemplate',{attrs:{"is-from-auto-setup":_vm.isFromAutoSetupValue,"selected-scope-data":_vm.selectedScope},model:{value:(_vm.selectTemplate),callback:function ($$v) {_vm.selectTemplate=$$v},expression:"selectTemplate"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }