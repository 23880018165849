const state: {
  navLinearLoading: boolean;
  error: boolean | string;
  snackBar: any;
  isLoading: boolean | string;
  isBalloonPopup: boolean | string;
  isBalloonDisabled: boolean | string;
} = {
  navLinearLoading: false,
  error: false,
  isLoading: false,
  snackBar: {
    isShowSnackbar: false,
    type: '',
    message: ''
  },
  isBalloonPopup: false,
  isBalloonDisabled: false
};
export default state;
