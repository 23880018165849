import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '../../state';
import toolState from './state';
import { MutationTypes } from './mutations';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  setLinearLoader = 'setLinearLoader',
  showSnackbar = 'showSnackbar',
  showBalloonPopup = 'showBalloonPopup',
  disableBalloonPopup = 'disableBalloonPopup',
  showLoader = 'showLoader'
}
type AuthActionCtx = ActionContext<typeof toolState, RootState>;
export interface ToolActions extends ActionTree<typeof toolState, RootState> {
  setLinearLoader: <T>(
    ctx: AuthActionCtx,
    payload: { func: (...args: any[]) => Promise<T> }
  ) => Promise<T>;
  showSnackbar: (ctx: AuthActionCtx, payload: any) => Promise<void>;
}
export const actions: ToolActions = {
  async setLinearLoader({ commit }, { func }) {
    try {
      commit(MutationTypes.LOADING_START);
      const ret = await func();
      commit(MutationTypes.LOADING_END);
      return ret;
    } catch (err) {
      commit(MutationTypes.GLOBAL_TOOL_ERR, err);
      throw new Error('Callback could not be completed');
    }
  },

  async showSnackbar({ commit }, data) {
    commit(MutationTypes.SHOW_SNACKBAR, data);
  },

  showBalloonPopup({ state }) {
    state.isBalloonPopup = !state.isBalloonPopup;
  },

  disableBalloonPopup({ state }) {
    state.isBalloonDisabled = !state.isBalloonDisabled;
  },

  showLoader({ state }) {
    state.isLoading = !state.isLoading;
  }
};
