import { MutationTree } from 'vuex';
import programState from './state';

// eslint-disable-next-line no-shadow
export const enum MutationTypes {
  SET_TEMPLATES = 'SET_TEMPLATES',
  SET_PATHWAYS = 'SET_PATHWAYS',
  SET_INDS = 'SET_INDS'
}

export const mutations = {
  [MutationTypes.SET_TEMPLATES](state, data) {
    state.templateList = data;
  },
  [MutationTypes.SET_PATHWAYS](state, data) {
    const pthList = data?.pathways?.filter(p => p.type === 'pathways');
    state.pathwaysList = pthList?.sort((a, b) => a.abbrName.localeCompare(b.abbrName));
  },
  [MutationTypes.SET_INDS](state, data) {
    if (data.pathways?.length) {
      const inds = data?.pathways?.filter(p => p.type === 'industries');
      inds?.filter(i => {
        if (data?.industries?.find(p => p.industryName === i.name)) {
          state.industryList.push({
            name: i.name,
            color: i.color,
            icon: i.icon,
            count: data?.industries?.find(p => p.industryName === i.name).count
          });
        }
        return i;
      });
    } else {
      state.industryList = [];
    }
  }
};
