import S3, { ManagedUpload } from 'aws-sdk/clients/s3';
import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store/state';
import fileStorageState from './state';
import { MutationTypes } from './mutations';
// eslint-disable-next-line no-shadow
export enum ActionTypes {
  uploadItem = 'uploadItem',
  deleteItems = 'deleteItems'
}
type FileStorageActionCtx = ActionContext<typeof fileStorageState, RootState>;

export interface FileStorageActions extends ActionTree<typeof fileStorageState, RootState> {
  uploadItem: <T extends File>(
    ctx: FileStorageActionCtx,
    payload: {
      item: T;
      name: string;
    }
  ) => Promise<ManagedUpload.SendData>;
  deleteItem: (
    ctx: FileStorageActionCtx,
    payload: { key: string }
  ) => Promise<S3.DeleteObjectOutput>;
}
export const actions: FileStorageActions = {
  /**
   * Uploads an item to the specified Amazon S3 bucket.
   *
   * @param {object} context - The Vuex store context object containing the `state` and `commit` functions.
   * @param {object} payload - The payload object containing the `item` to be uploaded.
   * @param {File} payload.item - The file to be uploaded.
   * @returns {Promise<object>} - A Promise that resolves to the upload response data from Amazon S3.
   * @throws {Error} - If there is an error during the upload process, a rejection with an error message is returned.
   */
  uploadItem({ state, commit }, { item, name }) {
    return new Promise((resolve, reject) => {
      state.bucket.upload(
        {
          Bucket: state.BUCKET,
          Key: name || `${Date.now()}_${item.name}`,
          Body: item,
          ContentType: item.type,
          ACL: 'public-read'
        },
        (err, data) => {
          if (err) {
            commit(MutationTypes.FILE_UPLOAD_ERROR);
            reject(new Error('Could not upload file'));
          }
          commit(MutationTypes.FILE_UPLOAD);
          resolve(data);
        }
      );
    });
  },
  /**
   * Deletes an item from the S3 bucket.
   * @param state - The Vuex state object.
   * @param commit - The Vuex commit function.
   * @param key - The key of the item to delete.
   * @returns A Promise that resolves with the S3 response data if the item was deleted successfully, or rejects with an error if the item could not be deleted.
   */
  deleteItem({ state, commit }, { key }) {
    return new Promise((resolve, reject) => {
      state.bucket.deleteObject(
        {
          Bucket: state.BUCKET,
          Key: key
        },
        (err, data) => {
          if (err) {
            commit(MutationTypes.FILE_DELETE_ERROR);
            reject(new Error('Could not Delete File'));
          }
          commit(MutationTypes.FILE_DELETE);
          resolve(data);
        }
      );
    });
  }
};
