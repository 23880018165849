const activitiesList = [
  'start',
  'scope',
  'community',
  'team',
  'train',
  'research',
  'tinker',
  'ideate',
  'pitch',
  'forum',
  'make',
  'demo',
  'interview',
  'offer',
  'welcome',
  'contact',
  'meet',
  'timesheet',
  'deliverable',
  'workspace',
  'signoff'
];
export default activitiesList;
