import { MutationTree } from 'vuex';
import userState from './state';

// eslint-disable-next-line no-shadow
export const enum MutationTypes {
  LOGIN_ANON = '🐱‍👤✔ Login Anonymous',
  LOGIN_ERROR = '🔐❌ Login Error',
  LOGIN_USER = 'LOGIN_USER',
  OPEN_DIALOG = 'Open Dialog',
  GET_PROGRAM = 'Get Program Doc',
  SET_ROLE = 'Set User Role',
  CLEAR_PROGRAM = 'Clear Program Doc'
}

export const mutations = {
  [MutationTypes.LOGIN_ANON](state, user: typeof userState.user) {
    state.user = user;
  },
  [MutationTypes.LOGIN_USER](state, user: typeof userState.user) {
    state.user = user;
  },
  [MutationTypes.OPEN_DIALOG](state, data) {
    state.isOpenDialog = data;
  },
  [MutationTypes.LOGIN_ERROR](state, errorCode: string) {
    state.error = errorCode;
  },
  [MutationTypes.GET_PROGRAM](state, data) {
    state.programDoc = data;
  },
  [MutationTypes.CLEAR_PROGRAM](state) {
    state.programDoc = null;
  },
  [MutationTypes.SET_ROLE](state, data) {
    state.userType = data;
  }
};
