import * as Airtable from 'airtable';

const base = new Airtable({
  apiKey: 'pattCKvlVPibAMZw1.6eb525b7643d3b8c59cfed53e7dd58128f181dca3f4a155ce614303fa1a29329'
}).base('appo8g9TjuRyPjwA9');

export const employersTable = base('Companies');

export const savedScopesTable = base('Saved Scopes');

export const organizationsTable = base('Organization');

export interface Employer {
  id: string;
  name: string;
  industry: string;
  website: string;
  shortDescription: string;
  employeeCount: string;
  schoolDistrict: string;
  pathways: string[];
  moreNotes: string;
  rank: number;
  jurisdiction: string;
}

export interface SavedScope {
  id?: string;
  userId: string;
  email?: string;
  deviceInfo?: {
    id: string;
    browser: string;
    platform: string;
    language: string;
    timeZone: string;
    firstSeen: string;
  };
  savedAt: string;
  savedInputs: Record<string, string>;
  scopesList: Array<any>;
  scopesPerEmployer: number;
  selectedScopes: Array<any>;
  name?: string;
}

export interface Organization {
  id: string;
  title: string;
  domain: string;
  domains: string[];
  organizationJoinLink: string;
  users: number;
  participants: number;
  programs: number;
  _id: string | null;
  members: number;
}

export async function fetchEmployers(options = {}): Promise<Employer[]> {
  const { searchQuery = '', pageSize = 100, offset = null } = options;

  let filterFormula = '';
  if (searchQuery) {
    const searchTerms = searchQuery
      .toLowerCase()
      .split(' ')
      .filter(term => term.length > 0);

    // Create the main filter that requires at least one term to match
    const mainFilter = searchTerms.map(
      term => `OR(
        SEARCH('${term}', LOWER({Company Name})),
        SEARCH('${term}', LOWER({Industry})),
        SEARCH('${term}', LOWER({Website})),
        SEARCH('${term}', LOWER({Short Description})),
        SEARCH('${term}', LOWER({School District})),
        SEARCH('${term}', LOWER({Pathways})),
        SEARCH('${term}', LOWER({More Notes})),
        SEARCH('${term}', LOWER({Jurisdiction}))
      )`
    );

    filterFormula = `AND(${mainFilter.join(',')})`;
  }

  try {
    const selectOptions: any = {
      view: 'Official',
      maxRecords: 10000,
      filterByFormula: filterFormula
    };

    if (typeof offset === 'number') {
      selectOptions.offset = offset;
    }

    const records = await employersTable.select(selectOptions).all();

    if (searchQuery) {
      const searchTerms = searchQuery
        .toLowerCase()
        .split(' ')
        .filter(term => term.length > 0);

      // Map records and calculate match count
      const recordsWithRank = records.map(record => {
        const allFields = [
          record.get('Company Name') as string,
          record.get('Industry') as string,
          record.get('Website') as string,
          record.get('Short Description') as string,
          record.get('School District') as string,
          (record.get('Pathways') as string[])?.join(' ') || '',
          record.get('More Notes') as string,
          record.get('Jurisdiction') as string
        ]
          .join(' ')
          .toLowerCase();

        // Count how many search terms match
        const matchCount = searchTerms.filter(term => allFields.includes(term)).length;

        return {
          id: record.id,
          name: record.get('Company Name') as string,
          industry: record.get('Industry') as string,
          jurisdiction: record.get('Jurisdiction') as string,
          website: record.get('Website') as string,
          matchCount
        };
      });

      // Sort by match count (descending) and then by name
      return recordsWithRank.sort((a, b) => {
        if (a.matchCount !== b.matchCount) {
          return b.matchCount - a.matchCount;
        }
        return a.name.localeCompare(b.name);
      });
    }

    return records.map(record => {
      const jurisdiction = record.get('Jurisdiction');
      console.log('Raw jurisdiction value:', jurisdiction);

      return {
        id: record.id,
        name: record.get('Company Name') as string,
        industry: record.get('Industry') as string,
        website: record.get('Website') as string,
        shortDescription: record.get('Short Description') as string,
        jurisdiction: jurisdiction ? String(jurisdiction) : '',
        pathways: (record.get('Pathways') as string[]) || [],
        employeeCount: record.get('Employee Count') as string,
        schoolDistrict: record.get('School District') as string,
        moreNotes: record.get('More Notes') as string,
        rank: record.get('Rank') as number
      };
    });
  } catch (error) {
    console.error('Error fetching employers:', error);
    throw error;
  }
}

export async function saveScopesToAirtable(data: SavedScope) {
  try {
    const fields = {
      'User ID': data.userId,
      Email: data.email,
      'Device Info': data.deviceInfo ? JSON.stringify(data.deviceInfo) : '',
      'Saved At': data.savedAt,
      'Saved Inputs': JSON.stringify(data.savedInputs),
      'Scopes List': JSON.stringify(data.scopesList),
      'Scopes Per Employer': data.scopesPerEmployer,
      'Selected Scopes': JSON.stringify(data.selectedScopes),
      Name: data.name || `Save from ${new Date(data.savedAt).toLocaleDateString()}`
    };

    const record = await savedScopesTable.create([{ fields }]);
    return record[0];
  } catch (error) {
    console.error('Error saving to Airtable:', error);
    throw error;
  }
}

export async function loadScopesFromAirtable(userId: string): Promise<SavedScope[]> {
  try {
    const records = await savedScopesTable
      .select({
        filterByFormula: `{User ID} = '${userId}'`,
        sort: [{ field: 'Saved At', direction: 'desc' }]
      })
      .all();

    return records.map(record => ({
      id: record.id,
      userId: (record.get('User ID') as string) || '',
      savedAt: (record.get('Saved At') as string) || '',
      savedInputs: record.get('Saved Inputs')
        ? JSON.parse(record.get('Saved Inputs') as string)
        : {},
      scopesList: record.get('Scopes List') ? JSON.parse(record.get('Scopes List') as string) : [],
      scopesPerEmployer: (record.get('Scopes Per Employer') as number) || 1,
      selectedScopes: record.get('Selected Scopes')
        ? JSON.parse(record.get('Selected Scopes') as string)
        : [],
      name: (record.get('Name') as string) || '',
      email: (record.get('Email') as string) || '',
      deviceInfo: record.get('Device Info') ? JSON.parse(record.get('Device Info') as string) : null
    }));
  } catch (error) {
    console.error('Error loading from Airtable:', error);
    throw error;
  }
}

export async function migrateDeviceSaves(deviceId: string, userId: string) {
  try {
    const deviceSaves = await loadScopesFromAirtable(deviceId);

    // Use Promise.all with map instead of for...of loop
    await Promise.all(
      deviceSaves.map(save =>
        saveScopesToAirtable({
          ...save,
          userId
        })
      )
    );

    return true;
  } catch (error) {
    console.error('Error migrating device saves:', error);
    throw error;
  }
}

export async function fetchOrganizations(options = {}): Promise<Organization[]> {
  const { searchQuery = '', searchField = 'title', pageSize = 100, offset = null } = options;

  let filterFormula = '';
  if (searchQuery) {
    if (searchField === 'domain') {
      filterFormula = `OR(${searchQuery
        .split(' ')
        .map(term => `SEARCH('${term}', LOWER({Domain}))`)
        .join(', ')})`;
    } else {
      filterFormula = `OR(${searchQuery
        .split(' ')
        .map(term => `SEARCH('${term}', LOWER({Title}))`)
        .join(', ')})`;
    }
  }

  try {
    const selectOptions: any = {
      view: 'Official',
      maxRecords: 1000,
      filterByFormula: filterFormula
    };

    if (typeof offset === 'number') {
      selectOptions.offset = offset;
    }

    const records = await organizationsTable.select(selectOptions).all();

    return records.map(record => ({
      id: record.id,
      title: record.get('Title') as string,
      domain: record.get('Domain') as string,
      domains: (record.get('Domains') as string[]) || [],
      organizationJoinLink: record.get('Organization Join Link') as string,
      users: (record.get('Users') as number) || 0,
      participants: (record.get('Participants') as number) || 0,
      programs: (record.get('Programs') as number) || 0,
      _id: (record.get('_id') as string) || null,
      members: (record.get('Members') as number) || 0
    }));
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
}
