import { ActionTree, ActionContext } from 'vuex';
import { MutationTypes } from './mutations';
import { RootState } from '../../state';
import formsState from './state';

type UserActionCtx = ActionContext<typeof formsState, RootState>;

export interface FormsActions extends ActionTree<typeof formsState, RootState> {
  postProps: (ctx: UserActionCtx, payload) => Promise<void>;
}

export const actions = {
  async postProps({ commit, dispatch }, data) {
    commit(MutationTypes.SET_PROPS, data);
  }
};
