// eslint-disable-next-line no-shadow
export const enum MutationTypes {
  SET_PROPS = 'Set Props'
}

export const mutations = {
  [MutationTypes.SET_PROPS](state, data) {
    state.forms = data;
  }
};
