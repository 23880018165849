











import Navbar from '@/components/Navbar.vue';
import { useToolGetters, useUserGetters } from '@/store';

export default {
  components: {
    Navbar
  },
  setup() {
    const { getLinearLoading: loading } = useToolGetters(['getLinearLoading']);
    return {
      getUser: useUserGetters(['getUser']).getUser,
      loading
    };
  }
};
