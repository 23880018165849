import { ObjectId } from 'bson';
import { GetterTree } from 'vuex';
import { RootState } from '../../state';
import userState from './state';

export const enum GetterTypes {
  getUser = 'getUser',
  getId = 'getId',
  getObjectId = 'getObjectId',
  getProgramDoc = 'getProgramDoc',
  isDialogOpen = 'isDialogOpen'
}
type UserGetterCtx<T> = (
  state: typeof userState,
  getters: any,
  rootState: RootState,
  rootGetters: any
) => T;
export interface UserGetters extends GetterTree<typeof userState, RootState> {
  getUser: UserGetterCtx<any | null>;
  getId: UserGetterCtx<string | null>;
  getObjectId: UserGetterCtx<ObjectId | null>;
  getProgramDoc: UserGetterCtx<any | null>;
  isDialogOpen: UserGetterCtx<boolean>;
  getUserType: UserGetterCtx<string | null>;
}
export const getters: UserGetters = {
  getUser: state => {
    return state.user;
  },
  getId: state => {
    const user = state.user as any;
    if (user) return user._id;
    return null;
  },
  getObjectId: state => {
    const user = state.user as any;
    if (user) return new ObjectId(user?._id);
    return null;
  },
  isDialogOpen: state => {
    return state.isOpenDialog;
  },
  getProgramDoc: state => {
    return state.programDoc;
  },
  getUserType: state => {
    return state.userType;
  }
};
