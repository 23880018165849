import { GetterTree } from 'vuex';
import { RootState } from '../../state';
import questionState from './state';

export const enum GetterTypes {
  getQuestions = 'getQuestions'
}
export interface QuestionGetters extends GetterTree<typeof questionState, RootState> {
  getQuestions: any;
}
export const getters: QuestionGetters = {
  getQuestions: state => {
    return state.questions;
  }
};
