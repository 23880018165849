






import { useUserGetters } from '@/store';

export default {
  setup() {
    return {
      getUser: useUserGetters(['getUser']).getUser
    };
  }
};
