import { ObjectId } from 'bson';
import { GetterTree } from 'vuex';
import { RootState } from '../../state';
import programState from './state';

export const enum GetterTypes {}
type ProgramGetterCtx<T> = (
  state: typeof programState,
  getters: any,
  rootState: RootState,
  rootGetters: any
) => T;
export type ProgramGetters = GetterTree<typeof programState, RootState>;
export const getters: ProgramGetters = {
  getTemplateList: state => {
    return state.templateList;
  },
  getPathwaysList: state => {
    return state.pathwaysList;
  },
  getIndustryList: state => {
    return state.industryList;
  }
};
