// eslint-disable-next-line no-shadow
export const enum MutationTypes {
  GET_QUESTIONS = 'Get Questions',
  ADD_QUESTION = 'Add Question',
  UPDATE_QUESTION = 'Update question'
}

export const mutations = {
  [MutationTypes.GET_QUESTIONS](state, data) {
    state.questions = data;
  },
  [MutationTypes.ADD_QUESTION](state, data) {
    state.questions.push(data);
  },
  [MutationTypes.UPDATE_QUESTION](state, data) {
    const index = state.questions.findIndex(q => q._id === data._id);
    state.questions[index] = data;
  }
};
