/* eslint-disable no-console */
import { MutationTree } from 'vuex';
import toolState from './state';
// eslint-disable-next-line no-shadow
export const enum MutationTypes {
  LOADING_START = '🔃 Loading Started',
  LOADING_END = '🏁 Loading Finished',
  GLOBAL_TOOL_ERR = '🌐❌ Global Tooling Error',
  SHOW_SNACKBAR = 'Show Snackbar'
}
export const mutations: MutationTree<typeof toolState> = {
  [MutationTypes.LOADING_START](state) {
    state.navLinearLoading = true;
  },
  [MutationTypes.LOADING_END](state) {
    state.navLinearLoading = false;
  },
  [MutationTypes.GLOBAL_TOOL_ERR](state, err) {
    state.error = err;
    console.error('Could not modify global tools state');
  },
  [MutationTypes.SHOW_SNACKBAR](state, data) {
    state.snackBar = { ...data };
  }
};
