




























import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { computed, ref, watchEffect } from '@vue/composition-api';
import { useUserGetters, useUserActions, useDbGetters } from '@/store';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);
interface FileType {
  source: string | null | undefined;
  options: {
    type: string;
  };
}
export default {
  components: {
    FilePond
  },
  props: {
    editable: {
      default: false,
      type: Boolean
    },
    size: {
      required: true,
      type: Number
    }
  },
  setup() {
    // init data
    const { getUser } = useUserGetters(['getUser']);
    const { uploadProfileImage } = useUserActions(['uploadProfileImage']);
    const { removeProfileImage } = useUserActions(['removeProfileImage']);
    const user = getUser.value;
    const myFiles = computed(() => {
      const files: FileType[] = [];
      if ((user as any)?.profile)
        files.push({
          source: (user as any)?.profile.medium,
          options: {
            type: 'local'
          }
        });
      return files;
    });

    const server = ref({
      process(fieldName, file, metadata, load, error, progress, abort) {
        const reader = new FileReader();
        reader.onload = async (fileEvent: Event) => {
          const base64Encoded = (fileEvent.target as any)?.result;
          const data = await uploadProfileImage({
            userID: user._id.toString(),
            profile_image: base64Encoded
          });
          if (data) {
            load(data.medium);
          }
        };
        reader.readAsDataURL(file);
        return {
          abort: () => {
            abort();
          }
        };
      },
      load(source, load, error, progress, abort) {
        // Should request a file object from the server here
        const myRequest = new Request(source);
        fetch(myRequest)
          .then(response => {
            response.blob().then(myBlob => {
              const blob = myBlob.slice(0, myBlob.size, 'image/jpeg');
              load(blob);
            });
          })
          .catch(err => error('Something went wrong'));
        return {
          abort: () => {
            abort();
          }
        };
      },
      async remove(source, load) {
        // delete profile images from user doc
        await removeProfileImage();
        load();
      }
    });
    const src = ref('');
    watchEffect(() => {
      if ((user as any)?.profile) src.value = (user as any)?.profile.medium;
    });
    const initials = computed(
      () =>
        `${(user as any)?.firstName?.toUpperCase().charAt(0)} ${(user as any)?.lastName
          ?.toUpperCase()
          .charAt(0)}`
    );
    return {
      myFiles,
      server,
      user,
      src,
      initials
    };
  }
};
