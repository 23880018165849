import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#4F4F4F', // primary
        secondary: '#FFFFFF', // white
        accent: '#BDBDBD', // grey
        error: '#EA6763', // red
        info: '#3C9CCC', // blue
        success: '#6EBA7F', // green
        warning: '#FDD35A', // yellow
        pink: '#eda1bf', // pink
        purple: '#ae90b0', // purple
        red: '#ea6764', // red
        blue: '#3c9dcd', // blue
        green: '#6eba80', // green
        yellow: '#fec34b', // yellow
        orange: '#f79961' // orange
      }
    }
  }
});
